<template>
  <div id="mentions_legales_cristal_web">
    <MentionsLegalesCristalWebDe v-if="country === 'de'" />
    <MentionsLegalesCristalWebFr v-else />
  </div>
</template>

<script>
import MentionsLegalesCristalWebFr from "@/components/legal/cw/MentionsLegalesCristalWebFr.vue";
import MentionsLegalesCristalWebDe from "@/components/legal/cw/MentionsLegalesCristalWebDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les mentions légales de Cristal Commandes dans la bonne langue.
 */
export default {
  name: "MentionsLegalesCristalWeb",
  components: {
    MentionsLegalesCristalWebFr,
    MentionsLegalesCristalWebDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
